import React, { useState } from 'react';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import { ILinkedinData } from '../types/linkedin-preview.type';
import MetaHelpers from '../../meta/helpers/meta.helpers';
import linkedinFeedFooterImage from '../images/linkedin-feed-footer.png';
import LinkedinFeedDetails from './linkedin-feed-details';
import LinkedinFeedHead from '../images/linkedin-feed-header.png';
import LinkedSponsoredHeader from '../components/linkedin-sponsored-header';

import '../styles/linkedin-feed-single-preview.scss';

const LinkedinFeedPreview: React.FC<ILinkedinData> = ({ cta, items }) => {
    const { commentary = '', title = '', description = '', source = '', asset } = items?.[0] || {};
    const [showFullText, setShowFullText] = useState(false);

    const handleShowFullText = () => {
        setShowFullText(true);
    };

    return (
        <div className="linkedin-feed-single">
            <div className="linkedin-feed-single__header">
                <img src={LinkedinFeedHead} alt="Linkedin Header" />
            </div>
            <div className="linkedin-feed-single__content">
                <LinkedSponsoredHeader />
                <div className="linkedin-feed-single__content__message">
                    {MetaHelpers.transformText(commentary || '', showFullText, handleShowFullText, 208)}
                </div>
                {asset ? (
                    <div className="linkedin-feed-single__content__asset-wrapper">{asset}</div>
                ) : (
                    <Typography variant="body2" color="textSecondary" className="linkedin-feed-single__content__placeholder">
                        {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                    </Typography>
                )}
                <LinkedinFeedDetails title={title} description={description} source={source} cta={cta} />
            </div>
            <div className="linkedin-feed-single__footer">
                <img src={linkedinFeedFooterImage} alt="Linkedin Footer" />
            </div>
        </div>
    );
};

export default LinkedinFeedPreview;
