import React from 'react';
import BrandSettings from 'components/data/BrandSettings';
import { IPinterestData } from '../types/pinterest-preview.type';

import '../styles/pinterest-link-ad-preview.scss';

const PinterestLinkAdPreview: React.FC<IPinterestData> = ({ items }) => {
    const { title, description, asset } = items?.[0] || {};
    const advertiser = BrandSettings.getAdvertiserData();

    return <div className="pinterest-link-ad-preview">Pinterest here</div>;
};

export default PinterestLinkAdPreview;
