import React from 'react';
import { IRedditData, RedditPlacementKey } from '../types/reddit-preview.type';
import RedditLinkAdPreview from '../components/reddit-link-ad-preview';

export const RedditFactory = (placementKey: RedditPlacementKey, redditData: IRedditData) => {
    let PreviewComponent: React.FC<IRedditData> | React.JSX.IntrinsicAttributes;

    switch (placementKey) {
        case 'reddit-link-ad':
            PreviewComponent = RedditLinkAdPreview;
            break;
        default:
            return <div>Ad type not available</div>;
    }

    return <PreviewComponent {...redditData} />;
};
