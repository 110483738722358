import React from 'react';
import '../styles/linkedin-feed-details.scss';

interface LinkedinFeedDetailsProps {
    title: string;
    description: string;
    source: string;
    cta: string;
    backgroundColor?: string;
}

const LinkedinFeedDetails: React.FC<LinkedinFeedDetailsProps> = ({ title, description, source, cta, backgroundColor }) => {
    return (
        <div className="linkedin-feed-details" style={{ backgroundColor: backgroundColor || '#F6F6F6' }}>
            <div className="linkedin-feed-details__left">
                <div className="linkedin-feed-details__left__name">{title}</div>
                <div className="linkedin-feed-details__left__description">{description}</div>
                <a href={source} className="linkedin-feed-details__left__caption" target="_blank" rel="noopener noreferrer">
                    {source}
                </a>
            </div>
            {cta && (
                <div className="linkedin-feed-details__right">
                    <a href={source} target="_blank" rel="noopener noreferrer">
                        <button className="linkedin-feed-details__right__cta-button">{cta}</button>
                    </a>
                </div>
            )}
        </div>
    );
};

export default LinkedinFeedDetails;
