import React from 'react';
import { useResources } from 'components/data/Resources/hooks/useResources';
import { ILinkedinData, LinkedinPlacementKey } from '../types/linkedin-preview.type';
import LinkedinFeedPreview from '../components/linkedin-feed-preview';

export const LinkedinFactory = (placementKey: LinkedinPlacementKey, linkedinData: ILinkedinData) => {
    const { resources, loading } = useResources<{ linkedin_cta: Record<string, string> }>(['linkedin_cta']);

    // Map the CTA to the correct label
    if (!loading) {
        linkedinData.cta = resources?.linkedin_cta?.[linkedinData.cta] || linkedinData.cta;
    }

    let PreviewComponent: React.FC<ILinkedinData> | React.JSX.IntrinsicAttributes;

    switch (placementKey) {
        case 'linkedin-feed':
            PreviewComponent = LinkedinFeedPreview;
            break;
        default:
            return <div>Ad type not available</div>;
    }

    return <PreviewComponent {...linkedinData} />;
};
