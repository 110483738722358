import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useComponentSize } from 'hooks/useComponentSize';
import Format from 'types/format.type';
import Translation from 'components/data/Translation';
import arrangeItemsInGrid, { CalculatedGridItem, GridItem } from 'helpers/arrangeItemsInGrid';
import { getCreativeInstance } from 'components/creatives-v2/helpers/creatives-factory';
import { IframeData, IframeOverwrites } from 'components/creatives-v2/creative-types/template-creative.class';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import { isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import { CreativeV2, CreativeV2Template } from '../creative-editor/types/creativeV2.type';
import CreativeItemWrapper from '../item-wrapper';
import { ICreativeOverview } from '../creative-overview/types/creative-overview.type';
import { CreativeEditorV2 } from '../creative-editor/types/creativeEditorV2.type';
import './styles/main.scss';

interface Classes {
    creativeItem?: string;
}

type Props = {
    creatives: CreativeV2[];
    view: ICreativeOverview['view'];
    enableScaling?: boolean;
    zoom?: number;
    singleViewKey?: string; // If given, only the creative with this key will be shown
    iframeOverwrites?: IframeOverwrites<IframeData>;
    headerComponent?: React.ReactElement;
    editable?: boolean;
    classes?: Classes;
    disableZoom?: boolean;
    hasOverlay?: boolean;
    infiniteViewerRef?: React.RefObject<any>;
};

interface ComponentStoreProps {
    selectedFormats: CreativeEditorV2['selectedFormats'];
}

const BORDER_WIDTH = 16;

const GridWrapper: React.FC<Props> = ({
    creatives,
    enableScaling = true,
    zoom = 1,
    singleViewKey,
    iframeOverwrites,
    headerComponent,
    editable,
    view,
    classes,
    disableZoom = false,
    hasOverlay = false,
    infiniteViewerRef
}) => {
    const { selectedFormats } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            selectedFormats: 'selectedFormats'
        }
    });

    const componentRef = useRef<HTMLDivElement>(null);
    const { width: componentWidth } = useComponentSize(componentRef);
    const hasComponentWidthBeenSet = useRef(false);

    const handleZoomOptimization = () => {
        if (view === 'canvasFree' || view === 'single') {
            const arrangedCreatives = getArrangedCreatives();
            if (arrangedCreatives?.length && !disableZoom) {
                optimizeZoom(arrangedCreatives);
            }
        }
    };

    useEffect(() => {
        // Trigger handleZoomOptimization only when componentWidth is set the first time
        if (!hasComponentWidthBeenSet.current && componentWidth > 0) {
            hasComponentWidthBeenSet.current = true;
            if (infiniteViewerRef?.current) {
                setTimeout(() => {
                    infiniteViewerRef?.current?.scrollTo(0, 0);
                });
            }
            handleZoomOptimization();
        }
    }, [componentWidth]);

    useEffect(() => {
        handleZoomOptimization();
    }, [singleViewKey]);

    const getArrangedCreatives = (): CalculatedGridItem<CreativeV2>[] | undefined => {
        let creativesToArrange: GridItem<CreativeV2>[] = [];

        creatives.forEach((creative) => {
            const creatives = getCreativeInstance(creative).prepareForArrangement();
            if (creatives) {
                creativesToArrange.push(...creatives);
            }
        });

        // If singleViewKey is given, only the creative with this key will be shown
        if (singleViewKey) {
            creativesToArrange = creativesToArrange.filter((creative) => creative.itemKey === singleViewKey);
        }

        // check in the array of objects what the biggest width is and if it is bigger than the component width
        // if it is, then we need to scale down the creatives
        const biggestFormatWidth = creativesToArrange.reduce(
            (prev, current) => {
                return prev.width > current.width ? prev : current;
            },
            { width: 0 }
        )?.width;

        const maxWidth = getGridMaxWidth(creativesToArrange, biggestFormatWidth);

        return arrangeItemsInGrid({
            items: creativesToArrange,
            maxWidth,
            packerOptions: { border: BORDER_WIDTH / zoom },
            extraFormatHeight: 40,
            enableScaling,
            zoom
        });
    };

    // Check if the zoom is too big or too small and adjust it, so that it fits the canvas
    const optimizeZoom = (arrangedCreatives: CalculatedGridItem<CreativeV2>[]) => {
        const maxX = arrangedCreatives.reduce((prev, current) => {
            return prev.x + prev.width > current.x + current.width ? prev : current;
        });

        const maxY = arrangedCreatives.reduce((prev, current) => {
            return prev.y + prev.height > current.y + current.height ? prev : current;
        });

        if (componentRef.current) {
            const newZoomX = componentRef.current.offsetWidth / ((maxX.x || 0) + maxX.width) - 0.05;
            const newZoomY = componentRef.current.offsetHeight / ((maxY.y || 0) + maxY.height) - 0.05;

            // Has to be between 0.2 and 1 and if so, take the smaller value of newZoomX and newZoomY
            const newCanvasZoom = Math.max(0.2, Math.min(Math.min(newZoomX, newZoomY), 1));

            if (typeof newCanvasZoom === 'number') {
                ComponentStoreHelpers.setModel('CreativeOverview', 'canvasZoom', newCanvasZoom);
            }
        }
    };

    const getGridMaxWidth = (creativesToArrange: GridItem<CreativeV2>[], biggestFormatWidth: number) => {
        // Auto scaling, so the formats inside the canvas will be scaled individually
        if (enableScaling) return componentWidth;

        if (creativesToArrange.length === 1) {
            const extraWidth = 2 * (BORDER_WIDTH / zoom) + 10; // 2 times the border width + 10px extra for rounding errors
            return Math.max(biggestFormatWidth + extraWidth, componentWidth);
        } else {
            return biggestFormatWidth * 2;
        }
    };

    // Additional styling for the whole item wrapper, depending on the creative settings
    const getAdditionalItemStyle = (creative: CalculatedGridItem<CreativeV2>) => {
        return {
            height: (creative.height * creative.scale || 1) + 64, // this 64 makes sure there is a padding at the bottom
            width: creative.width * creative.scale || 1,
            top: creative.y,
            left: creative.x
        };
    };

    // TODO Check memoizing
    const arrangedCreatives = getArrangedCreatives();

    /**
     * This is used to determine if the format is a screenshot format or not.
     * When not in the CreativeEditorV2, we don't have a creative so we can't take a screenshot.
     */
    const formatToScreenshot: Format | undefined = (() => {
        const creative = ComponentStoreHelpers.getItem('CreativeEditorV2', 'creative') as CreativeV2Template;
        if (!creative) return;
        const activeFormats = CreativeV2FormatHelpers.getActiveFormats(creative);
        return CreativeV2FormatHelpers.getOptimalScreenshotFormat(activeFormats);
    })();

    return (
        <div className="creative-item-grid-wrapper" ref={componentRef}>
            {(!arrangedCreatives || arrangedCreatives.length === 0) && (
                <div className="creative-item-grid-wrapper__empty">
                    <EmptyState
                        illustration={<Illustration size="large" illustration="fallback" color="disabled" />}
                        primaryText={Translation.get('noFormatsFound', 'bricks')}
                    />
                </div>
            )}
            {arrangedCreatives &&
                arrangedCreatives?.map((creative, index) => {
                    const hasOverlay = getCreativeInstance(creative.item).hasOverlay();
                    const takeScreenshot =
                        formatToScreenshot && creative.item.data.templateType === 'displayAdDesigned' && formatToScreenshot.key === creative.itemKey;

                    return (
                        <div
                            key={creative.item.id + creative.itemKey}
                            className={classNames('creative-item-grid-wrapper__item', classes?.creativeItem)}
                            style={getAdditionalItemStyle(creative)}>
                            <CreativeItemWrapper
                                key={index}
                                creativeGridItem={creative}
                                zoom={zoom}
                                iframeOverwrites={iframeOverwrites}
                                headerComponent={headerComponent}
                                selectedFormats={selectedFormats}
                                editable={editable}
                                hasOverlay={hasOverlay}
                                takeScreenshot={takeScreenshot}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

export default GridWrapper;
