import React, { useRef, useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useIsInView } from 'hooks/useIsInView';
import classNames from 'classnames';
import cloneDeep from 'helpers/cloneDeep';
import { IframeData, IframeOverwrites } from 'components/creatives-v2/creative-types/template-creative.class';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import { CalculatedGridItem } from 'helpers/arrangeItemsInGrid';
import ComponentStore from 'components/data/ComponentStore';
import { CreativeV2 } from '../creative-editor/types/creativeV2.type';
import DynamicAssetV2 from '../dynamic-asset-v2';
import ItemWrapperHeader from './components/item-wrapper-header';
import { CreativeEditorV2 } from '../creative-editor/types/creativeEditorV2.type';
import './styles/main.scss';

interface Props {
    creativeGridItem: CalculatedGridItem<CreativeV2>;
    zoom?: number;
    hideOutsideView?: boolean;
    iframeOverwrites?: IframeOverwrites<IframeData>;
    headerComponent?: React.ReactElement;
    editable?: boolean;
    hasOverlay?: boolean;
    takeScreenshot?: boolean;
    selectedFormats?: CreativeEditorV2['selectedFormats'];
}

const CreativeItemWrapper: React.FC<Props> = ({
    creativeGridItem,
    zoom = 1,
    hideOutsideView = true,
    iframeOverwrites,
    headerComponent,
    editable = false,
    hasOverlay = false, // The overlay is there for iframes, to make sure that in the infinite viewer your mouse is not over the iframe itself
    takeScreenshot = false, // This is a flag to take a screenshot of the creative
    selectedFormats
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const isInView = useIsInView(containerRef);

    const getAdditionalItemStyle = () => ({
        transition: zoom === 1 ? 'box-shadow 0.2s, transform 0.2s' : 'box-shadow 0s, transform 0s',
        borderRadius: 8 / zoom
    });

    // Additional styling for the creative itself, dependend on the creative settings
    const getAdditionalCreativeStyle = () => {
        return {
            width: creativeGridItem.width * creativeGridItem.scale || 1,
            height: creativeGridItem.height * creativeGridItem.scale || 1
        };
    };

    /**
     * Toggles the format of the creative.
     */
    const toggleFormat = () => {
        const formatKey = creativeGridItem.itemKey;
        if (!formatKey) return;

        const clonedSelectedFormats = cloneDeep(selectedFormats);
        if (!clonedSelectedFormats) return;

        if (clonedSelectedFormats.includes(formatKey)) return;
        clonedSelectedFormats.push(formatKey);

        ComponentStore.setModel('CreativeEditorV2', 'selectedFormats', clonedSelectedFormats);
    };

    const isCreativeItemActive = selectedFormats && selectedFormats.includes(creativeGridItem.itemKey);
    const isCreativeItemBlurred = selectedFormats && selectedFormats.length > 0 && !isCreativeItemActive;

    return (
        <div
            className={classNames('creative-item-wrapper', {
                'creative-item-wrapper--editable': editable,
                'creative-item-wrapper--is-active': isCreativeItemActive,
                'creative-item-wrapper--is-blurred': isCreativeItemBlurred
            })}
            ref={containerRef}
            style={getAdditionalItemStyle()}
            onClickCapture={toggleFormat}>
            <ItemWrapperHeader zoom={zoom} creativeGridItem={creativeGridItem} headerComponent={headerComponent} selectedFormats={selectedFormats} />
            <ClickAwayListener onClickAway={() => setIsFocused(false)}>
                <div className="creative-item-wrapper__creative" style={getAdditionalCreativeStyle()}>
                    {hideOutsideView && !isInView && (
                        <div className="creative-item-wrapper__creative__not-in-view">
                            <CircularProgress />
                        </div>
                    )}
                    {(!hideOutsideView || (hideOutsideView && isInView)) && (
                        <DynamicAssetV2
                            creative={creativeGridItem.item}
                            formatKey={creativeGridItem.itemKey}
                            scale={creativeGridItem.scale}
                            iframeOverwrites={iframeOverwrites}
                            takeScreenshot={takeScreenshot}
                        />
                    )}
                    {hasOverlay && (
                        <div
                            className={classNames('creative-item-wrapper__creative__overlay', {
                                'creative-item-wrapper__creative__overlay--is-focused': isFocused
                            })}
                            onClick={() => setIsFocused(editable)}
                        />
                    )}
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default CreativeItemWrapper;
