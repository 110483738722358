import React from 'react';
import { IPinterestData, PinterestPlacementKey } from '../types/pinterest-preview.type';
import PinterestLinkAdPreview from '../components/pinterest-link-ad-preview';

export const PinterestFactory = (placementKey: PinterestPlacementKey, pinterestData: IPinterestData) => {
    let PreviewComponent: React.FC<IPinterestData> | React.JSX.IntrinsicAttributes;

    switch (placementKey) {
        case 'pinterest-link-ad':
            PreviewComponent = PinterestLinkAdPreview;
            break;
        default:
            return <div>Ad type not available</div>;
    }

    return <PreviewComponent {...pinterestData} />;
};
