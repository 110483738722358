import React from 'react';
import BrandSettings from 'components/data/BrandSettings';
import { IRedditData } from '../types/reddit-preview.type';

import '../styles/reddit-link-ad-preview.scss';

const RedditLinkAdPreview: React.FC<IRedditData> = ({ body, items }) => {
    const { call_to_action, display_url, destination_url, asset } = items?.[0] || {};
    const advertiser = BrandSettings.getAdvertiserData();

    return <div className="reddit-link-ad-preview">Reddit here</div>;
};

export default RedditLinkAdPreview;
