import React from 'react';
import BrandSettings from 'components/data/BrandSettings';
import Avatar from 'components/ui-components-v2/Avatar';
import iconMoreHorizGrey from '../images/icon-more-horiz-grey.svg';
import LinkedinFallbacklogo from '../images/linkedin-cape-Logo.png';

import '../styles/linkedin-sponsored-header.scss';

const LinkedinSponsoredHeader: React.FC = () => {
    const advertiser = BrandSettings.getAdvertiserData();

    return (
        <div className="linkedin-sponsored-header">
            <Avatar src={advertiser?.logo || LinkedinFallbacklogo} className="linkedin-sponsored-header__avatar" />
            <div className="linkedin-sponsored-header__info">
                <div className="linkedin-sponsored-header__name">{advertiser?.name || 'Cape'}</div>
                <div className="linkedin-sponsored-header__text">
                    <span className="linkedin-sponsored-header__text__followers">
                        12,345 followers
                        <br />
                        Promoted
                    </span>
                </div>
            </div>
            <img src={iconMoreHorizGrey} alt="Linkedin Sponsored Icon" />
        </div>
    );
};

export default LinkedinSponsoredHeader;
